import React, { Fragment } from 'react'
import { Link } from 'gatsby'

import SEO from '../../components/seo'
import Layout from '../../components/layout'
import Servizio from '../../components/Servizio'

import ParcelImg from '../../images/parcel.jpg'

const paginaServizio = {
  titolo: 'Parcel',
  parent: null,
  servizi: [
    {
      id: 1,
      nome: '',
      imgUrl: ParcelImg,
      paragrafi: [
        {
          id: 1,
          testo:
            'Parcel è il servizio camionistico veloce “door to door” d’eccellenza. Collega oltre 30 paesi europei con consegne di colli fino a 40 kg in 48-72 ore.',
        },
        {
          id: 2,
          testo: 'Grazie a Parcel l’Europa è più vicina!',
        },
      ],
      pesoDimensioni: [
        {
          id: 1,
          descrizione: 'Peso massimo per collo',
          valore: '40 kg',
        },
        {
          id: 2,
          descrizione: 'Altezza massima',
          valore: '60 cm',
        },
        {
          id: 3,
          descrizione: 'Larghezza massima',
          valore: '80 cm',
        },
        {
          id: 4,
          descrizione: 'Lunghezza massima',
          valore: '200 cm',
        },
        {
          id: 5,
          descrizione: 'Misure massime: lato maggiore + latox2 + latox2',
          valore: '300 cm',
        },
      ],
      vantaggi: [
        {
          id: 1,
          testo: 'Servizio di consegna door-to door in 36 paesi europei',
        },
        {
          id: 2,
          testo:
            'Tempi di consegna regolari: 48 ore nei centri economici europei Germania, Francia, Austria, Spagna, Belgio, Olanda, Lussemburgo; 72-96 ore nei paesi europei più distanti',
        },
        {
          id: 3,
          testo:
            'Assicurazione: responsabilità Vettoriale come da convenzione C.M.R.',
        },
        {
          id: 4,
          testo:
            'Tracking & Tracing costante con informazioni complete sullo stato della spedizione',
        },
        {
          id: 5,
          testo:
            'Il livello qualitativo è lo stesso in tutte le nazioni, grazie ad un unico sistema di gestione della qualità',
        },
        {
          id: 6,
          testo:
            'Prenotazione della spedizione tramite telefono, e-mail, Weblabeling (per maggiori informazioni contatta la tua Sede di riferimento), o eventualmente via fax',
        },
      ],
      compatibileCon: null,
    },
  ],
}

const Parcel = () => {
  return (
    <Layout>
      <SEO
        title={paginaServizio.titolo}
        description={paginaServizio.servizi[0].paragrafi[0].testo}
      />
      <section className="py-8 px-6">
        <h1 className="text-3xl sm:text-4xl font-light text-gls-blue-900 leading-tight">
          {!!paginaServizio.parent && (
            <Link
              to={paginaServizio.parent.linkUrl}
              className="mr-2 text-lg text-gls-blue-600 hover:text-gls-blue-700"
            >
              {paginaServizio.parent.nome} &raquo;
            </Link>
          )}
          {paginaServizio.titolo}
        </h1>
      </section>
      <section className="bg-white lg:rounded-lg shadow-xl">
        {paginaServizio.servizi.map((servizio, index) => {
          return (
            <Fragment key={servizio.id}>
              <Servizio servizio={servizio} />
              {index < paginaServizio.servizi.length - 1 && <hr />}
            </Fragment>
          )
        })}
      </section>
    </Layout>
  )
}

export default Parcel
